import dayjs from 'dayjs';
import localforage from 'localforage';
import type { IpeData } from '~/types/API/analytics';
import type { Consumption, ConsumptionDetailed } from '~/types/API/consumption';
import type { Meter } from '~/types/meter';

export const useApi = () => {
  // Composables
  const config = useRuntimeConfig();
  const { $firebase } = useNuxtApp();

  // Variables
  let userTokenId: string = '';

  // Auth Functions
  const getToken = async () => {
    const user = await $firebase.currentUser;
    const token = await user?.getIdToken();
    userTokenId = token;
  };

  const getNuxtHeaders = async () => {
    await getToken();
    return {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${userTokenId}`,
    };
  };

  const postUser = async (id: string, data: any) => {
    const url = `${config.public.backendUrl}/v1/user/${id}`;
    await $fetch<Consumption[]>(url, {
      method: 'POST',
      body: data,
      headers: await getNuxtHeaders(),
    });
  };

  const getConsumptionByMeter = async (options: {
    meterId: number;
    start: string;
    end: string;
    aggregationLevel: string;
  }): Promise<Consumption[]> => {
    const url = `${config.public.backendUrl}/v1/consumption`;
    let data: Consumption[] = [];

    if (options.aggregationLevel === 'hour' || options.aggregationLevel === 'minute') {
      const interval = options.aggregationLevel === 'hour' ? 60 : 5;
      data = await $fetch<Consumption[]>(`${url}/detailed`, {
        params: {
          meter_id: options.meterId,
          start: options.start,
          end: options.end,
          interval: interval,
        },
        headers: await getNuxtHeaders(),
      });
    } else {
      data = await $fetch<Consumption[]>(url, {
        params: {
          meter_id: options.meterId,
          start: options.start,
          end: options.end,
          aggregation_level: options.aggregationLevel,
        },
        headers: await getNuxtHeaders(),
      });
    }

    return data;
  };

  const getConsumption = async (
    options: {
      meterId: number;
      start: string;
      end: string;
      aggregationLevel: string;
    },
    signal?: AbortSignal,
  ): Promise<Consumption[]> => {
    const url = `${config.public.backendUrl}/v1/consumption`;
    const key = `consumption-${options.meterId}-${dayjs(options.start).format('YYYY-MM-DD')}-${dayjs(options.end).format('YYYY-MM-DD')}-${options.aggregationLevel}`;
    const cache: { date: Date; consumption: Consumption[] } = JSON.parse(await localforage.getItem<string>(key));

    let cacheFound = false;
    if (cache) {
      if (dayjs().diff(dayjs(cache.date), 'hours') <= 2) {
        cacheFound = cache.consumption ? true : false;
        return cache.consumption;
      }
      localforage.removeItem(key);
    }
    if (!cacheFound) {
      const data = await $fetch<Consumption[]>(url, {
        params: {
          meter_id: options.meterId,
          start: options.start,
          end: options.end,
          aggregation_level: options.aggregationLevel,
        },
        headers: await getNuxtHeaders(),
        signal,
      });
      await localforage.setItem(key, JSON.stringify({ date: dayjs().toDate(), consumption: data }));
      return data;
    }
  };

  const getDetailedConsumption = async (
    options: {
      meterId: number;
      start: string;
      end: string;
      interval: number;
    },
    signal?: AbortSignal,
  ): Promise<ConsumptionDetailed[]> => {
    const url = `${config.public.backendUrl}/v1/consumption/detailed`;
    const key = `consumption-detailed-${options.meterId}-${dayjs(options.start).format('YYYY-MM-DD-hh:mm')}-${dayjs(options.end).format('YYYY-MM-DD-hh:mm')}-${options.interval}`;
    const cache: { date: Date; consumption_detailed: ConsumptionDetailed[] } = JSON.parse(await localforage.getItem<string>(key));

    let cacheFound = false;
    if (cache) {
      if (dayjs().diff(dayjs(cache.date), 'minutes') < 15) {
        cacheFound = cache.consumption_detailed ? true : false;
        return cache.consumption_detailed;
      }
      localforage.removeItem(key);
    }
    if (!cacheFound) {
      const data = await $fetch<ConsumptionDetailed[]>(url, {
        params: {
          meter_id: options.meterId,
          start: options.start,
          end: options.end,
          interval: options.interval,
        },
        headers: await getNuxtHeaders(),
        signal,
      });
      await localforage.setItem(key, JSON.stringify({ date: dayjs().toDate(), consumption_detailed: data }));
      return data;
    }
  };

  const getOffProductionByMeterId = async (options: { meterId: number; start: string; end: string }) => {
    const url = `${config.public.backendUrl}/v1/off_production/${options.meterId}`;
    const resultAxios = await $fetch<Consumption[]>(url, {
      params: {
        start: options.start,
        end: options.end,
      },
      headers: await getNuxtHeaders(),
    });
    return resultAxios;
  };

  const getIpeData = async (options: { id: number; start: string; end: string }): Promise<IpeData> => {
    const url = `${config.public.backendUrl}/v1/energy_performance_index/${options.id}`;
    const resultAxios = await $fetch<IpeData>(url, {
      params: {
        start: options.start,
        end: options.end,
      },
      headers: await getNuxtHeaders(),
    });
    return resultAxios;
  };

  const getAnalyticDataByMonth = async (meterTypes: string[] = []): Promise<{ meter: Meter; consumption: Consumption[] }[]> => {
    const meterTypesOrDefault = meterTypes.length === 0 ? ['enedis', 'grdf'] : meterTypes;

    const meters = meterStore().getMeters.filter((m) => meterTypesOrDefault.includes(m.meter_type.name));

    const promises = meters.map((meter) => {
      return getConsumptionByMeter({
        meterId: meter.id,
        start: dayjs().subtract(18, 'month').date(1).format('YYYY-MM-DD'),
        end: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        aggregationLevel: 'month',
      }).then((consumption) => {
        return {
          meter,
          consumption,
        };
      });
    });

    return Promise.all(promises);
  };

  const getObjectiveConsumption = async (id: number) => {
    const url = `${config.public.backendUrl}/v1/consumption/objective/${id}`;
    const resultAxios = await $fetch<Consumption[]>(url, {
      headers: await getNuxtHeaders(),
    });
    return resultAxios;
  };

  const getContractAnalysis = async (analyticId: string | number) => {
    const url = `${config.public.backendUrl}/v1/contract_analysis/detailed/${analyticId}`;
    const resultAxios = await $fetch<Consumption[]>(url, {
      headers: await getNuxtHeaders(),
    });
    return resultAxios;
  };

  return {
    getConsumption,
    getConsumptionByMeter,
    getObjectiveConsumption,
    getContractAnalysis,
    getIpeData,
    getOffProductionByMeterId,
    postUser,
    getAnalyticDataByMonth,
    getDetailedConsumption,
  };
};
